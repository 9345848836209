import React from 'react'
import './TopNav.css'
import { Link } from 'react-router-dom'

const TopNav = () => {
  return (
    <nav className='navbar'>
       <Link to={'./'}>
      <div style={{margin:'1.8em',color:'white', cursor:'pointer'}}>
       <h3>Employee'sData</h3>
      </div>
       </Link>
    </nav>
  )
}

export default TopNav
