import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth"

const firebaseConfig = {
    apiKey: "AIzaSyCFUqjYIdZkZYeFgnm0zomtMl3sOptckFs",
    authDomain: "admin-employee-d3926.firebaseapp.com",
    projectId: "admin-employee-d3926",
    storageBucket: "admin-employee-d3926.appspot.com",
    messagingSenderId: "447107785800",
    appId: "1:447107785800:web:9acea960674a7acc59e47d",
    measurementId: "G-59S6F8G3QY"
  };
  
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;