import React, { useEffect, useState } from "react";
import "./MainSection.css";
import { IoMdAdd } from "react-icons/io";
import { axiosGet } from "../../axiosServices";
import { Link } from "react-router-dom";
import SEO from "../SEO";

const MainSection = ({ setEmployeeId }) => {
  const [showModal, setShowModal] = useState(false);
  const [employees, setEmployees] = useState([]);

  const getAllEmployee = async () => {
    try {
      const res = await axiosGet("/api/getemployeedata");
      setEmployees(res?.data);
    } catch (err) {
      console.log(err);
    }
  };
  console.log(employees?.data?.map((empdata) => empdata));

  useEffect(() => {
    getAllEmployee();
  }, []);
  return (
    <>
    <SEO dynamicTitle="Admin-Employee | Home" />
      <main className="mainContainer">
        <div className="mainWrapper">
          <h1>
            People <span className="emp-count">{employees?.data?.length}</span>
          </h1>
          <div style={{display:'flex', justifyContent:'space-between'}}>
            <div className="employeeHeader">
              <Link to={"/addemployee"} color="#fffff">
                <button className="add-btn">
                  <IoMdAdd size="20" color="#fffff" />
                  Add Employee Details
                </button>
              </Link>
            </div>
            <aside>
            <Link to={"/admindashboard"} color="#fffff">
              <button className="add-btn">
                AdminDashboard
              </button>
              </Link>
            </aside>
          </div>
        </div>
      </main>
    </>
  );
};

export default MainSection;
