import React, { useEffect, useState } from "react";
import "./MainSection.css";
import { BiSearch } from "react-icons/bi";
import { IoMdAdd, IoMdLogOut } from "react-icons/io";
import Card from "../AdminDashboard/components/Card";
import { axiosGet } from "../../axiosServices";
import { Link, useNavigate } from "react-router-dom";
import { useUserAuth } from '../../utils/context/adminAuthcontext'
import UpdateEmpdetails from "../UpdateEmpdetails/UpdateEmpdetails";
import { useData } from "../../utils/useEmployedata";

const keys = ["first_name", "last_name", "email", "phone"]
function filter(search, empData) {
    const getfilteredData = empData.filter((item) => keys.some((key) => item[key].toLowerCase().includes(search?.trim().toLowerCase())))
    return  getfilteredData ;
}

const Admin = () => {
    const allEmployee = useData()
    const [editModal, setEditModal] = useState(false);
    const [empById, setEmpById] = useState([]);
    const [reRender, setReRender] = useState(false);
    const [filterdata, setFilterdata] = useState([])
    const [search, setsearch] = useState()
    const { logOut, stateuser } = useUserAuth();
    const empData  = allEmployee?.data
    const navigate = useNavigate();
    console.log(editModal +"  editpopUP")
    console.log(reRender+"  render")
    useEffect(() => {
        setFilterdata(empData)
    }, [editModal, reRender, empData]);

    const handleSearch = (e) => {
        e.preventDefault();
        setFilterdata(filter(search, empData))
      }
      console.log(filterdata)
    const handleSignOut = async () => {
        try {
            await logOut();
            console.log("loged-out sucessfully")
            navigate("/");
        } catch (error) {
            console.log(error);
        }
    };
    const handleSignIn = () => {
        navigate("/login");
    };

    const handleEdit =  () => {
        setEditModal(true);
    };
    const handleReRender = () => {
        setReRender(true);
    };

    return (
        <>
            {
                 editModal ? <UpdateEmpdetails setEditModal={setEditModal} empById={empById} /> :''
            }
            <main className="mainContainer">
                <div className="mainWrapper">
                    <div style={{ marginTop: '6em', marginBottom: '1.5em', display: 'flex', justifyContent: 'space-between' }}>
                        <h1>
                            People <span className="emp-count">{empData?.length}</span>
                        </h1>
                        <button className="add-btn" onClick={handleSignOut}>
                            <IoMdLogOut size="20" color="#fffff" />
                            Lougout
                        </button>
                    </div>
                    <div className="employeeHeader">
                        <div className="searchBox">
                            <form onSubmit={handleSearch}>
                            <input
                                type="text"
                                placeholder="Search by name, email, designation etc"
                                onChange={(e) => setsearch(e.target.value)}
                                value={search}
                                required
                            />
                            <button type="submit"
                             style={{
                                margin:'0 0 0 0.8em',
                                height: "2.2em",
                                width: "8em",
                                borderRadius: 5,
                                border: "none",
                                boxShadow: "1px 1px 0px 2px rgba (0,0,0,0.3)",
                                backgroundColor: "#3969b5",
                                cursor: "pointer",
                                color:'white',
                                display:'flex',
                                justifyContent:'center',
                                alignItems:"center"
                              }}> <BiSearch size={15} />SEARCH</button>
                            </form>
                            <p style={{color:'red', margin:'1em'}}>{filterdata?.length === 0 ? "Data not found please wait or try again later " : ""}</p>
                        </div>
                        <Link to={'/addemployee'} color="#fffff"  >
                            <button className="add-btn" >
                                <IoMdAdd size="20" color="#fffff" />
                                Add Employee Details
                            </button>
                        </Link>
                    </div>
                    <div className="employees" >
                        {
                            filterdata && filterdata?.map((emp) => {
                                return <div key={emp._id}
                                //  onClick={() => setEmployeeId(emp._id)} 
                                >
                                    <Card
                                        empData={emp}
                                        handleEdit={handleEdit}
                                        setEmpById={setEmpById}
                                        handleReRender={handleReRender}
                                    />
                                </div>
                            })
                        }
                    </div>
                </div>
            </main>
        </>
    );
};

export default Admin;
