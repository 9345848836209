export default function validate(values) {
  let errors = {};
  console.log(errors)

  if (!values.first_name) {
    errors.first_name = 'first name is required';
  } else if (values.first_name.length < 3) {
    errors.first_name = 'first name is to shot!';
  }
  if (!values.last_name) {
    errors.last_name = 'last name is required';
  } else if (values.last_name.length < 3) {
    errors.last_name = 'last name is to shot!';
  }
  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid';
  }
  if (!values.phone) {
    errors.phone = 'phone number is required'
  } else if (!/^[789]\d{9}$/.test(values.phone)) {
    errors.phone = 'phone number is invalid';
  }
  if (!values.address) {
    errors.address = 'address is required';
  } else if (values.address.length < 6) {
    errors.address = 'address is to shot! enter your full address';
  }
  if (!values.city) {
    errors.city = 'address is required';
  } else if (values.city.length <= 2) {
    errors.city = 'city is to shot! enter your city';
  }
  if (!values.postal_code) {
    errors.postal_code = 'postal code is required';
  } else if (!/^[1-9][0-9]{5}$/.test(values.postal_code)) {
    errors.postal_code = 'postal code is incorrect';
  }
  if (!values.date_of_joining) {
    errors.date_of_joining = 'date of joining is required';
  } else if (!/^(19|20)\d{2}(\/|-)(0[1-9]|1[1,2])(\/|-)(0[1-9]|[12][0-9]|3[01])$/.test(values.date_of_joining)) {
    errors.date_of_joining = 'date of joining is incorrect format';
  }
  if (!values.pan_number) {
    errors.pan_number = 'pan number is required';
  } else if (!/([A-Z]){5}([0-9]){4}([A-Z]){1}$/.test(values.pan_number)) {
    errors.pan_number = 'pan number is incorrect';
  }
  if (!values.adhar_number) {
    errors.adhar_number = 'adhar number is required';
  } else if (!/^\d{4}\d{4}\d{4}$/.test(values.adhar_number)) {
    errors.adhar_number = 'adhar number is incorrect';
  }
  if (!values.date_of_birth) {
    errors.date_of_birth = 'date of birth is required';
  } else if (!/^(19|20)\d{2}(\/|-)(0[1-9]|1[1,2])(\/|-)(0[1-9]|[12][0-9]|3[01])$/.test(values.date_of_birth)) {
    errors.date_of_birth = 'date of birth is incorrect format';
  }
  if (!values.bank_name) {
    errors.bank_name = 'bank name is required';
  } else if (values.bank_name.length < 3) {
    errors.bank_name = 'bank name is to shot! enter your bank name correctly';
  }
  if (!values.bank_holder_name) {
    errors.bank_holder_name = 'bank holder name is required';
  } else if (values.bank_holder_name.length <= 3) {
    errors.bank_holder_name = 'bank holder name is to shot! enter your bank holder name correctly';
  }
  if (!values.bank_account_number) {
    errors.bank_account_number = 'bank account number is required';
  } else if (!/^\d{9,18}$/.test(values.bank_account_number)) {
    errors.bank_account_number = 'enter your bank account number correctly 8-19';
  }
  if (!values.IFSC_code) {
    errors.IFSC_code = 'IFSC code is required';
  } else if (!/^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/.test(values.IFSC_code)) {
    errors.IFSC_code = 'enter your IFSC code  correctly';
  }
  if (!values.age) {
    errors.age = 'age is required';
  } else if (values.age < 18) {
    errors.age = 'enter your age correctly';
  }
  return errors;
};

