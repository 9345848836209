import React from 'react'
import { useState } from "react";
import './formstyle.css'
import validate from '../../utils/validation/FormValidation'
import useForm from '../../utils/useForms';
import { AxiosError } from 'axios';
import SEO from '../SEO';

const AddempDetails = () => {

    const stateData = {
        india: ["Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh",
            "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand", "Karnataka", "Kerala",
            "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya", "Mizoram", "Nagaland", "Odisha",
            "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura", "Uttar Pradesh",
            "Uttarakhand", "West Bengal"],
        // Add more countries and their states here
    };
    const handleCountryChange = (event) => {
        // const country = event.target.value;
        setcountry(event.target.value);
        setstate('');
    };
    const handleStateChange = (event) => {
        // const state = event.target.value;
        setstate(event.target.value);
    };

    const [country, setcountry] = useState("");
    const [state, setstate] = useState("");
    const [marital_status, setmarital_status] = useState("");
    const [spouse_name, setspouse_name] = useState("");
    const [marriage_date, setmarriage_date] = useState("");
    const [gender_status, setgender_status] = useState("");


    const emponchangevalues = {
        country, state, marital_status, spouse_name, marriage_date, gender_status
    };

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(login, validate, emponchangevalues);

    function login() {
        console.log('No errors, submit callback called!');
    }

    return (
        <>
        <SEO dynamicTitle="Admin-Employee | AddEmployee" />
            <div className='add_employee'>
                <form action="" onSubmit={handleSubmit} >
                    <div className="modalBox">
                        <div className="modalHeaderbox">
                            <div className="modalHeader">
                                <h2>New Employee Details</h2>
                            </div>
                        </div>

                        <div className="modalInner">
                            <div className="input-container">
                                <div className="input-box">
                                    <label htmlFor="">First Name</label>
                                    <input
                                        type="text"
                                        name="first_name"
                                        required
                                        onChange={handleChange}
                                        value={values.first_name || ''}
                                    />
                                    <p className="help is-danger" style={{ color: 'red', marginBottom: '4px' }}>{errors.first_name}</p>
                                </div>
                                <div className="input-box">
                                    <label htmlFor="">Last Name</label>
                                    <input
                                        type="text"
                                        name="last_name"
                                        required
                                        onChange={handleChange}
                                        value={values.last_name || ''}
                                    />
                                    <p className="help is-danger" style={{ color: 'red', marginBottom: '4px' }}>{errors.last_name}</p>
                                </div>
                            </div>
                            <div className="input-container">
                                <div className="input-box">
                                    <label htmlFor="">Email Address</label>
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder='abcd@gmail.com'
                                        required
                                        onChange={handleChange}
                                        value={values.email || ''}
                                    />
                                    <p className="help is-danger" style={{ color: 'red', marginBottom: '4px' }}>{errors.email}</p>
                                </div>
                                <div className="input-box">
                                    <label htmlFor="">Phone +91</label>
                                    <input
                                        type="text"
                                        name="phone"
                                        placeholder='10 digits mobile number'
                                        required
                                        onChange={handleChange}
                                        value={values.phone || ''}
                                    />
                                    <p className="help is-danger" style={{ color: 'red', marginBottom: '4px' }}>{errors.phone}</p>
                                </div>
                            </div>
                            <div className="input-box">
                                <label htmlFor="">Current Address</label>
                                <input
                                    type="text"
                                    name="address"
                                    required
                                    placeholder='H-no / residential name  ,street , dist '
                                    onChange={handleChange}
                                    value={values.address || ''}
                                />
                                <p className="help is-danger" style={{ color: 'red', marginBottom: '4px' }}>{errors.email}</p>
                            </div>
                            <div className="input-container">
                                <div className="input-box">
                                    <label htmlFor="">Select Country</label>
                                    <select id="country" value={country} onChange={handleCountryChange} required>
                                        <option value="">select Country</option>
                                        <option value="india">India</option>
                                    </select>
                                </div>
                                <div className="input-box">
                                    <label htmlFor="">Select State</label>
                                    <select id="state" value={state} onChange={handleStateChange} required>
                                        <option value="">Select State</option>
                                        {stateData[country] &&
                                            stateData[country].map((data, index) => (
                                                <option key={index} value={data}>
                                                    {data}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>
                            <div className="input-container">
                                <div className="input-box">
                                    <label htmlFor="">city </label>
                                    <input
                                        type="text"
                                        name="city"
                                        required
                                        onChange={handleChange}
                                        value={values.city || ''}
                                    />
                                    <p className="help is-danger" style={{ color: 'red', marginBottom: '4px' }}>{errors.city}</p>
                                </div>
                                <div className="input-box">
                                    <label htmlFor="">Postal/Zip code </label>
                                    <input
                                        type="number"
                                        name="postal_code"
                                        required
                                        onChange={handleChange}
                                        value={values.postal_code || ''}
                                    />
                                    <p className="help is-danger" style={{ color: 'red', marginBottom: '4px' }}>{errors.postal_code}</p>

                                </div>
                            </div>
                            <div className="input-box">
                                <label htmlFor="">Date of Joining</label>
                                <input
                                    type="date"
                                    name="date_of_joining"
                                    required
                                    onChange={handleChange}
                                    value={values.date_of_joining || ''}
                                />
                                <p className="help is-danger" style={{ color: 'red', marginBottom: '4px' }}>{errors.date_of_joining}</p>

                            </div>

                            <div className="identitydocs-container">
                                <div className="identitydocs-modalHeader">
                                    <h2>Identity documents</h2>
                                </div>

                                <div className="input-container">
                                    <div className="input-box">
                                        <label htmlFor="">PAN number</label>
                                        <input
                                            type="text"
                                            name="pan_number"
                                            required
                                            onChange={handleChange}
                                            value={values.pan_number || ''}
                                        />
                                        <p className="help is-danger" style={{ color: 'red', marginBottom: '4px' }}>{errors.pan_number}</p>

                                    </div>
                                </div>
                                <div className="input-container">
                                    <div className="input-box">
                                        <label htmlFor="">Adhar number</label>
                                        <input
                                            type="text"
                                            name="adhar_number"
                                            required
                                            onChange={handleChange}
                                            value={values.adhar_number || ''}
                                        />
                                        <p className="help is-danger" style={{ color: 'red', marginBottom: '4px' }}>{errors.adhar_number}</p>

                                    </div>
                                </div>
                            </div>
                            <div className="Personal-container">
                                <div className="Personal-modalHeader">
                                    <h2>Personal Details </h2>
                                </div>
                                <div className="merital-input-container">
                                    <div className="input-box">
                                        <select
                                            name="marital_status"
                                            style={{ color: "black" }}
                                            required
                                            onChange={(e) => setmarital_status(e.target.value)}
                                            value={marital_status}
                                            placeholder="Please Select Marital Status"
                                            data-toggle="tooltip"
                                            title="Select your Marital Status "
                                        >
                                            <option value="">Please Select Marital Status</option>
                                            <option value="Married">Married</option>
                                            <option value="single">Single</option>
                                        </select>
                                        <div
                                            id="fgSpouse"
                                            className="input-box"
                                            style={{
                                                display: marital_status && marital_status === "Married" ? "block" : "none",
                                            }}
                                        >
                                            <label htmlFor="" className="control-label mb-1">
                                                Spouse Name
                                            </label>
                                            <input
                                                type="text"
                                                name="spouse_name"
                                                style={{ color: "black" }}
                                                placeholder="Mention your Spouse Name"
                                                data-toggle="tooltip"
                                                title="Mention your Spouse Name if Applicable"
                                                onChange={(e) => setspouse_name(e.target.value)}
                                                value={spouse_name}
                                                {...(marital_status && marital_status === "Married"
                                                    ? { required: true }
                                                    : {})}
                                            />
                                            &nbsp;&nbsp;&nbsp;
                                            <label htmlFor="" className="control-label mb-1">
                                                Marriage date
                                            </label>
                                            <input
                                                type="date"
                                                name="marriage_date"
                                                onChange={(e) => setmarriage_date(e.target.value)}
                                                value={marital_status && marriage_date}
                                                {...(marital_status === "Married"
                                                    ? { required: true }
                                                    : {})}
                                            />
                                        </div>
                                    </div>
                                </div>
                                &nbsp;
                                <div className="radio-input-container">
                                    <div className="radio-input-box">
                                        <p>gender status</p>
                                        <label>
                                            <input
                                                type="radio"
                                                name="gender_status"
                                                value={"male"}
                                                onChange={(e) => setgender_status(e.target.value)}

                                            />
                                            Male
                                        </label>
                                        <label>
                                            <input
                                                type="radio"
                                                name="gender_status"
                                                value={"female"}
                                                onChange={(e) => setgender_status(e.target.value)}
                                            />
                                            Female
                                        </label>
                                    </div>
                                </div>
                                <div className="input-box">
                                    <label htmlFor="">date of birth</label>
                                    <input
                                        type="date"
                                        name="date_of_birth"
                                        required
                                        onChange={handleChange}
                                        value={values.date_of_birth || ''}
                                    />
                                    <p className="help is-danger" style={{ color: 'red', marginBottom: '4px' }}>{errors.date_of_birth}</p>

                                </div>
                                <div className="input-box">
                                    <label htmlFor="">Age</label>
                                    <input
                                        type="number"
                                        name="age"
                                        required
                                        onChange={handleChange}
                                        value={values.age || ''}
                                    />
                                    <p className="help is-danger" style={{ color: 'red', marginBottom: '4px' }}>{errors.age}</p>

                                </div>
                            </div>
                            <div className="identitydocs-container">
                                <div className="identitydocs-modalHeader">
                                    <h2>Bank account details</h2>
                                </div>
                                <div className="input-box">
                                    <label htmlFor="">Bank Name</label>
                                    <input
                                        type="text"
                                        name="bank_name"
                                        required
                                        onChange={handleChange}
                                        value={values.bank_name || ''}
                                    />
                                    <p className="help is-danger" style={{ color: 'red', marginBottom: '4px' }}>{errors.bank_name}</p>

                                </div>
                                <div className="input-box">
                                    <label htmlFor="">Bank Holder Name</label>
                                    <input
                                        type="text"
                                        name="bank_holder_name"
                                        required
                                        onChange={handleChange}
                                        value={values.bank_holder_name || ''}
                                    />
                                    <p className="help is-danger" style={{ color: 'red', marginBottom: '4px' }}>{errors.bank_holder_name}</p>

                                </div>
                                <div className="input-box">
                                    <label htmlFor="">Bank Account Number</label>
                                    <input
                                        type="text"
                                        name="bank_account_number"
                                        required
                                        onChange={handleChange}
                                        value={values.bank_account_number || ''}
                                    />
                                    <p className="help is-danger" style={{ color: 'red', marginBottom: '4px' }}>{errors.bank_account_number}</p>

                                </div>
                                <div className="input-box">
                                    <label htmlFor="">IFSC Code</label>
                                    <input
                                        type="text"
                                        name="IFSC_code"
                                        required
                                        onChange={handleChange}
                                        value={values.IFSC_code || ''}
                                    />
                                    <p className="help is-danger" style={{ color: 'red', marginBottom: '4px' }}>{errors.IFSC_code}</p>

                                </div>

                            </div>
                        </div>
                        <div className="modalFooter">
                            <button className="add-btn" type="submit">
                                {/* {loading ? "Saving..." : "Save Details"} */}
                                save details
                            </button>
                        </div>
                    </div>
                </form>

            </div>
        </>
    )
}

export default AddempDetails;