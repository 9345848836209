import { useState, useEffect } from 'react';
import { axiosPost } from "../axiosServices";
import { useNavigate } from 'react-router-dom';

const useForm = (callback, validate, emponchangevalues) => {

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isformdata, setisformdata] = useState()
  const navigate = useNavigate()
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
      console.log("no errors")
      console.log(isformdata)
      createEmployee(isformdata)
    }
  }, [errors]);

  const createEmployee = async (formData) => {
    try {
      const res = await axiosPost("/api/createemployee", formData);
      console.log(formData);
      console.log(res);
      if (res.data.status === true) {
        alert(res.data.message)
        navigate('/')
      } else {
        alert(res.data.message);
      }
    } catch (err) {
      console.log(err);
      alert(err.response.data.data);
    }
  };

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    setErrors(validate(values));
    const formData = new FormData();
    formData.append("first_name", values.first_name);
    formData.append("last_name", values.last_name);
    formData.append("email", values.email);
    formData.append("phone", values.phone);
    formData.append("address", values.address);
    formData.append("city", values.city);
    formData.append("postal_code", values.postal_code);
    formData.append("country", emponchangevalues.country);
    formData.append("state", emponchangevalues.state);
    formData.append("date_of_joining", values.date_of_joining);
    formData.append("pan_number", values.pan_number);
    formData.append("adhar_number", values.adhar_number);
    formData.append("marital_status", emponchangevalues.marital_status);
    formData.append("spouse_name", emponchangevalues.spouse_name);
    formData.append("marriage_date", emponchangevalues.marriage_date);
    formData.append("gender_status", emponchangevalues.gender_status);
    formData.append("date_of_birth", values.date_of_birth);
    formData.append("age", values.age);
    formData.append("bank_name", values.bank_name);
    formData.append("bank_holder_name", values.bank_holder_name);
    formData.append("bank_account_number", values.bank_account_number);
    formData.append("IFSC_code", values.IFSC_code);
    setIsSubmitting(true);
    setisformdata(formData)

  };

  const handleChange = (event) => {
    event.persist();
    setValues(values => ({ ...values, [event.target.name]: event.target.value }));
  };

  return {
    handleChange,
    handleSubmit,
    values,
    errors,
  }
};

export default useForm;