import React, { useState } from "react";
import "./UpdateEmpstyle.css";
import { axiosPut } from "../../axiosServices";
import { IoMdCloseCircle } from "react-icons/io";

// const EditDetailsModal = ({ empById, setEditModal }) => {
const UpdateEmpdetails = ({ empById, setEditModal }) => {
  const [loading, setLoading] = useState(false);
  const [profile_img, setprofilePhoto] = useState("");
  const [adhar_Link, setAdharLink] = useState("");
  const [pan_Link, setPanLink] = useState("");
  const [education_docs, setEducation_docs] = useState("");
  const handleClose = () => {
    setEditModal(false);
  };
  console.log(empById)
  const values = {
    education_docs,
    adhar_Link,
    pan_Link,
    profile_img,
  };
  const handleEdit = async (e) => {
    e.preventDefault();
    try {
      const res = await axiosPut(`/api/getemployeedata/${empById}`, values);
      console.log(res);
      if (res.data.status === true) {
        setLoading(true)
        alert("updated sucessfully")
        alert(res.data.message)
        setEditModal(false);
        window.location.reload();
      } else {
        alert("updated error")
        alert(res.data.message);
      }
    } catch (err) {
      console.log(err);
      alert("updated error")
      alert(err.response.data.data)
    }
  };

  return (
    <div className="modalContainer">
      <form action="" onSubmit={handleEdit}>
        <div className="modalBox-form">
          <div className="modalHeaderbox">
            <div className="modalHeader-form">
              <h2>Add GDrive links</h2>
            </div>
            <div className="modalClose">
              <IoMdCloseCircle size={25} onClick={handleClose} />
            </div>
          </div>
          <div className="modalInner-form">
            <div className="input-container-form">
              <div className="input-box-form">
                <label htmlFor="">pan card</label>
                <input
                  type="text"
                  name="firstname"
                  required
                  onChange={(e) => setPanLink(e.target.value)}
                  values={pan_Link}
                />
              </div>
              <div className="input-box-form">
                <label htmlFor="">Adhar card </label>
                <input
                  type="text"
                  name="lastname"
                  required
                  onChange={(e) => setAdharLink(e.target.value)}
                  values={adhar_Link}
                />
              </div>
            </div>
            <div className="input-box-form">
              <label htmlFor="">profile Photo</label>
              <input
                type="text"
                name="lastname"
                required
                onChange={(e) => setprofilePhoto(e.target.value)}
                values={profile_img}
              />
            </div>

            <div className="input-container-form">
              <div className="input-box-form">
                <label htmlFor="">education docs</label>
                <input
                  type="text"
                  name="email"
                  required
                  onChange={(e) => setEducation_docs(e.target.value)}
                  values={education_docs}
                />
              </div>
            </div>
          </div>
          <div className="modalFooter-form">
            <button className="add-btn" type="submit">
              {/* {loading ? "Editing" : "Edit and Save"} */}
              Edit and Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateEmpdetails;
