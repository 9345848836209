import { useEffect, useState } from "react"
import { axiosGet } from "../axiosServices";

export const useData = () => {
    const [getallEmployee, setAllEmployee] = useState([]);
    useEffect(() => {
        allEmployee()
    }, [])
    const allEmployee = async () => {
        try {
            const res = await axiosGet("/api/getemployeedata");
            setAllEmployee(res?.data);
        } catch (err) {
            console.log(err);
        }
    };
    return getallEmployee;
}