import React, { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { axiosDelete } from "../../../axiosServices";
import { Link } from "react-router-dom";

const Card = ({ empData, handleEdit, handleReRender, setEmpById }) => {
  const { first_name, last_name, email, phone } = empData;

  const [dropDown, setDropdown] = useState(false);

  const getInitials = (first_name, last_name) => {
    const firstName = first_name.split(" ")[0].toUpperCase();
    const lastName = last_name.split(" ")[0].toUpperCase();
    return firstName.charAt(0) + lastName.charAt(0);
  };
  const generateBackground = () => {
    const colors = [
      "#4285F4",
      "#004D3C",
      "#DB4437",
      "#F4B400",
      "#0F9D58",
      "#808080",
      "#8E44AD",
      "#F39C12",
      "#FF6F61",
      "#4169E1",
      "#5FC8D8",
    ];
    return colors[Math.floor(Math.random() * colors.length)];
  };
  const initials = getInitials(first_name, last_name);
  const backgroundColor = generateBackground();
  const handleDelete = async (id) => {
    try {
      const res = await axiosDelete(`/api/getemployeedata/${id}`);
      console.log(res);
      window.location.reload();
      handleReRender();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="card-component" style={{ border: "solid black 0.01px" }}>
      <div className="card-inner">
        <div className="dropdownContainer">
          <BsThreeDotsVertical
            size={20}
            onClick={() => setDropdown(!dropDown)}
          />
          {dropDown && (
            <ul className="dropdown" onMouseLeave={() => setDropdown(false)}>
              <li
                onClick={() => {
                  handleEdit(empData._id);
                  setEmpById(empData._id);
                }}
              >
                Edit
              </li>
              <li onClick={() => handleDelete(empData._id)}>Delete</li>
            </ul>
          )}
        </div>
        <div
          style={{
            fontFamily: "Arial, Helvetica, sans-serif",
            width: 100,
            height: 100,
            borderRadius: 50,
            color: "#fff",
            backgroundColor,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "1.7em",
          }}
        >
          {initials}
        </div>
        <div className="emp-detail">
          <h3>
            {first_name} {last_name}
          </h3>
          <p color="gray">{email}</p>
        </div>
      </div>
      <div className="job-role">
        <p>{phone}</p>
      </div>
      <Link to={`/details/${empData._id}`}>
        <button
          style={{
            margin: "1em 4em",
            fontSize: "1em",
            height: "2em",
            width: "8.5em",
            borderRadius: 5,
            border: "none",
            boxShadow: "1px 1px 0px 2px rgba (0,0,0,0.3)",
            backgroundColor: "#3969b5",
            cursor: "pointer",
            color:'white'
          }} >
          MORE DETAILS
        </button>
      </Link>
    </div>
  );
};

export default Card;
