import { useState } from 'react';
import './App.css';
import MainSection from './components/MainSection/MainSection';
import TopNav from './components/TopNav/TopNav';
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import EmpfullDetails from './components/EmpfullDetails/EmpfullDetails';
import AddempDetails from './components/AddempDetails/AddempDetails';
import Loginadmin from './components/Login/Loginadmin';
import { UserAuthContextProvider } from './utils/context/adminAuthcontext';
import AdminauthFallback from './components/AdminDashboard/AdminauthFallback';
import UpdateEmpdetails from './components/UpdateEmpdetails/UpdateEmpdetails';
import Error from './Errorrouter';
import SEO from './components/SEO';

function Applayout() {
  return (
    <div className="App">
      <UserAuthContextProvider>
      <SEO dynamicTitle="Admin-Employee | Home" />
        <TopNav />
        <Outlet />
      </UserAuthContextProvider>
    </div>
  );
}

function App() {
  const [employeeId, setEmployeeId] = useState('')
  console.log(employeeId)
  const Approuter = createBrowserRouter([
    {
      path: "/",
      element: <Applayout />,
      children: [
        {
          path: '/',
          element: <MainSection />,
          errorElement:<Error/>
        },
        {
          path: '/addemployee',
          element: <AddempDetails />,
          errorElement:<Error/>
        },
        {
          path: '/details/:paramsid',
          element: <EmpfullDetails />,
          errorElement:<Error/>
        },
        {
          path: '/admindashboard',
          element: <AdminauthFallback />,
          errorElement:<Error/>
        },
        {
          path: '/adminlogin',
          element: <Loginadmin />,
          errorElement:<Error/>
        }, 
        {
          path: '/updateemp',
          element: <UpdateEmpdetails />,
          errorElement:<Error/>
        },
      ]
    }
  ]);
  return (
    <div className="App">
      <RouterProvider router={Approuter} />
    </div>
  );
}

export default App;
