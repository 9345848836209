import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './login.css'
import { useUserAuth } from '../../utils/context/adminAuthcontext';
import SEO from '../SEO';


const Loginadmin = () =>{
    const [email, setemail] = useState('');
    const [password, setpassword] = useState('');
    const [error, seterror] = useState('');
    const { logIn } = useUserAuth();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        seterror('')
        try {
            await logIn(email, password)
            navigate("/")
        }
        catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            seterror(errorMessage)
            console.log(errorCode, errorMessage)
        };
    };

    return (
        <>
        <SEO dynamicTitle="Admin-Employee | Login" />
        <form className="container"
            onSubmit={handleSubmit}
            >
            <div className='login-header'>
            <h3>Login Authentication</h3>
            </div>
            <input label='Email address' type='email' placeholder='username'
                value={email}
                onChange={(e) => {
                    setemail(e.target.value)
                }} required />
            <input label='Password' type='password' placeholder='password'
                value={password}
                onChange={(e) => {
                    setpassword(e.target.value)
                }} required />
            <p style={{ color: 'red' }}>{error}</p>
            <button type='submit'> Login </button>

        </form>
                </>
    );
}

export default Loginadmin;