import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { axiosGet } from '../../axiosServices'
import './empfullDetails.css'


const EmpfullDetails = () => {
  const { paramsid } = useParams()
  // console.log(paramsid)

  const [empData, setempData] = useState([])
  const [empById, setEmpById] = useState()
  
  const getEmployeeById = async () => {
    try {
      const res = await axiosGet(`/api/getemployeedata/${paramsid}`)
        .then((res) => {
          setempData(res?.data.data)
          console.log(res?.data?.data)
        })
        .catch((err) => err.message)
    }
    catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    getEmployeeById()
  }, [])

  const { IFSC_code, address, adhar_number, age,
    bank_account_number, bank_holder_name, bank_name, city, country,
    date_of_birth, date_of_joining, email, first_name, gender_status,
    last_name, marital_status, marriage_date, pan_number,
    phone, postal_code, profile_photo, spouse_name, state, education_docs, pan_Link, adhar_Link, profile_img } = empData
  console.log(profile_photo)
  return (
    <div>
      <h2>Full Detail</h2>
      <section className='emp_Details_section'>
        <div className="employeeDetail">
          <div className='emp_info'>
            <h1>{first_name} {last_name}</h1>
            <p  > {city},{state}, {postal_code}</p>
            <p className='date' >Start Date : {date_of_joining}</p>
            <p  >Role : {"emp_role"}</p>
          </div>
        </div>
        <div className='contact_main'>
          <section className='contact_emp'>
            <div className='contact_header'>
              <h3>Contact Information</h3>
            </div>
            <div>
              <p  >phone : {phone}</p>
              <p  >email : {email}</p>
              <p  >address : {address}</p>
            </div>
          </section>
        </div>
        <div className='contact_main'>
          <section className='contact_emp'>
            <div className='contact_header'>
              <h3>Personal Information</h3>
            </div>
            <div>
              <p  >Date of Birth : {date_of_birth}</p>
              <p  >Gender : {gender_status}</p>
              <p  >Marital status : {marital_status}</p>
              <p >Spouse: {spouse_name ? spouse_name : '---'}</p>
              <p >Marriage date: {marriage_date ? marriage_date : '---'}</p>
            </div>
          </section>
        </div>
        <div className='education_main'>
          <section className='education_emp'>
            <div className='education_header'>
              <h3>Identity documents</h3>
            </div>
            <div>
              <div>
                <h4 style={{ margin: '1em 0' }}>Adhar number :{adhar_number}</h4>
              </div>
              <div >
                <h4 style={{ margin: '1em 0' }}>Pan number : {pan_number}</h4>
              </div>
            </div>
          </section>
        </div>
        <div className='contact_main'>
          <section className='contact_emp'>
            <div className='contact_header'>
              <h3>Personal Information</h3>
            </div>
            <div>
              <p  >bank name : {bank_name}</p>
              <p  >bank holder name : {bank_holder_name}</p>
              <p  >bank account number : {bank_account_number}</p>
              <p >IFSC_code : {IFSC_code}</p>
            </div>
          </section>
        </div>
        <div className='contact_main'>
          <section className='contact_emp'>
            <div className='contact_header'>
              <h3>Personal documents</h3>
            </div>
            <div>
              <p  >education docs { education_docs ? <Link to={education_docs} target="_blank">Link</Link> : "---"}  </p>
              <p  >pan card  {pan_Link ? <Link to={pan_Link} target="_blank">Link</Link> : "---"}</p>
              <p  >adhar card  {adhar_Link ? <Link to={adhar_Link} target="_blank">Link</Link> : "---"}</p>
              <p >profile image  {profile_img ? <Link to={profile_img} target="_blank">Link</Link> : "---"}</p>
            </div>
          </section>
        </div>
      </section>
    </div>
  )
}

export default EmpfullDetails