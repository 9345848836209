import React, { Suspense, useState } from 'react'
import { useUserAuth } from '../../utils/context/adminAuthcontext'
import { NavLink } from 'react-router-dom'
import Admin from './Admin'
import Loginadmin from '../Login/Loginadmin'
import './adminstyle.css'
import SEO from '../SEO'

const AdminauthFallback = () => {
    const { stateuser } = useUserAuth();
    
    return  stateuser ?  ( 
        
        stateuser && <>
        <SEO dynamicTitle="Admin-Employee | AdminDashboard" />
        <Admin/>
        </>
    ) : (
        <>
        <Suspense fallback={<p>checking.......</p>}>
        <div className='login_box'>
                <div className='cart_login'>
                    <div className='login_content'>
                        <h3>authorized Login</h3>
                        <p>To access your AdminDashboard,please Log in to authorized admin.</p>
                        <div className='lgin_btn'>
                            <NavLink to='/adminlogin'>
                            <button>LOGIN</button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </Suspense>
        </>
    )

}

export default AdminauthFallback